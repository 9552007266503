import Checkbox from '@/components/form/checkbox.vue';
import Loading from '@/components/loading.vue';
import Modal from '@/components/modal.vue';
import Offcanvas from '@/components/offcanvas.vue';
import Scrollable from '@/components/scrollable.vue';
import router from '@/router';
import store from '@/store';
import { ITableTranslations } from '@/translations';
import formatDate from '@/utils/formatDate';
import search from '@/utils/search';
import sort from '@/utils/sort';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { IModal } from '../';
import { ICheckbox } from '../form/';

import { IAvailableKeys, IAvailableTypes, ITable, ITableState } from '.';

@Component({
  name: 'Table',
  components: { Modal, Offcanvas, Loading, Checkbox, Scrollable }
})
class Table extends Vue {

  @Prop({ required: true }) public table!: ITable<IAvailableTypes>;

  @Getter('getTableTranslations') public translations!: ITableTranslations;

  @Getter('userToken') public token!: string;
  public formatDate = formatDate;
  public searchTimeout!: number;
  public data = [{}] as IAvailableTypes[];
  public itemCheckboxes: ICheckbox[] = [];
  public modal: IModal = {
    isActive: false
  };
  public searchSettingsModal: IModal = {
    isActive: false
  };
  public searchLoading = false;
  public selectedItems: number[] = [];
  public allChecked = false;
  public selectedOnly = false;
  public currentPage = 1;
  public pageSize = 20;
  public selectedPages: number[] = [];
  public tableHeight = 700;
  public searchString = '';
  public selectAllCheckbox!: ICheckbox;
  public firstLoad = false;

  @Watch('table') public onPropertyChanged() {
    if (!this.firstLoad) {
      this.firstLoad = true;
      const localStorageProp = `${process.env.VUE_APP_LOCAL_STORAGE_TABLE_PROP}_${this.table.id}`;

      const tableStateString = localStorage.getItem(localStorageProp);
      if (tableStateString) {
        const tableState = JSON.parse(tableStateString);
        this.pageSize = this.table.pageSize = tableState.pageSize;
        this.table.searchFields = tableState.searchFields;
        this.table.visibleFields = tableState.visibleFields;
      }
    }
    let selectedItems = [0];

    if (this.table.selectionGetter) {

      selectedItems = store.getters[this.table.selectionGetter];

    }

    selectedItems.forEach(id => {
      if (this.selectedItems.indexOf(id) === -1) {
        this.selectedItems.push(id);
      }
    });

    if (this.table.data) {
      this.data = this.table.data;
      this.table.data.forEach(item => {
        if (item.id && !this.itemCheckboxes[item.id]) {
          this.itemCheckboxes[item.id] = {
            id: item.id,
            label: '',
            value: selectedItems.indexOf(item.id) > -1 ? true : false,
            onClick: this.onSelectedItem.bind(this, item)
          };
        }
      });
      if (this.selectedOnly) {
        this.toggleSelectedOnlyVisibility(true);
      }
      if (this.searchString !== '') {
        this.searchData(this.searchString);
      }
    }
  }
  public created() {
    this.selectAllCheckbox = {
      id: `check-all-${this.table.id}`,
      label: '',
      value: false,
      onClick: this.toggleAll.bind(this),
    };

    if (this.table.data) {
      this.data = this.table.data;
    }

    if (this.table.pageSize) {
      this.pageSize = this.table.pageSize;
    }

    this.setTableMaxHeight();
    window.addEventListener('resize', $event => this.setTableMaxHeight());

  }
  public setTableMaxHeight() {
    if (this.table.insideModal) {
      this.tableHeight = window.outerHeight - 340;
    } else {
      this.tableHeight = window.outerHeight - 320;
    }
  }
  public openRoute(route: string) {
    router.push(route);
  }
  public changePageSize(pageSize: number) {

    const previousSelectedPages = [...this.selectedPages];

    this.selectedPages = [];

    if ((this.pageSize / pageSize) > 1) {

      const additionalSelectedPages = Math.round(this.pageSize / pageSize);

      previousSelectedPages.forEach(selectedPage => {

        selectedPage = (additionalSelectedPages * (selectedPage - 1)) + 1;

        for (let i = 0; i < additionalSelectedPages; i++) {

          this.selectedPages.push(selectedPage + i);
          if ((this.currentPage === selectedPage) && !this.allChecked) {
            this.toggleCheckAll(true);
          }

        }

      });

    } else {

      const selectedPagesDifferential = Math.round(1 / (this.pageSize / pageSize)) - 1;

      let pagesGrouped = 0;

      this.toggleCheckAll(false);

      previousSelectedPages.forEach(selectedPage => {

        if (pagesGrouped === selectedPagesDifferential) {

          this.selectedPages.push(selectedPage - selectedPagesDifferential);
          pagesGrouped = 0;
          if ((this.currentPage === (selectedPage - selectedPagesDifferential)) && this.allChecked) {
            this.toggleCheckAll(true);
          }

        } else {

          pagesGrouped++;

        }

      });
    }

    this.pageSize = pageSize;
    this.saveTableState();

  }
  public saveTableState() {

    const tableState: ITableState = {
      pageSize: this.pageSize,
      searchFields: this.table.searchFields as any,
      visibleFields: this.table.visibleFields
    };
    const localStorageProp = `${process.env.VUE_APP_LOCAL_STORAGE_TABLE_PROP}_${this.table.id}`;

    localStorage.setItem(localStorageProp, JSON.stringify(tableState));
  }
  public nextPage() {
    this.currentPage = this.currentPage + 1;
    const index = this.selectedPages.indexOf(this.currentPage);
    if (index === -1) {
      this.toggleCheckAll(false);
    } else {
      this.toggleCheckAll(true);
    }
  }
  public previousPage() {
    this.currentPage = this.currentPage - 1;
    const index = this.selectedPages.indexOf(this.currentPage);
    if (index === -1) {
      this.toggleCheckAll(false);
    } else {
      this.toggleCheckAll(true);
    }
  }
  public toggleCheckAll(checked: boolean) {
    const $checkbox = document.getElementById('checkbox-check-all') as HTMLInputElement;
    if ($checkbox) {
      $checkbox.checked = checked;
    }
    this.allChecked = checked;
    this.selectAllCheckbox = {
      ...this.selectAllCheckbox,
      value: checked
    };
  }
  public onSelectedItem(item: IAvailableTypes, selectAll = false) {
    if (item.id) {
      const index = this.selectedItems.indexOf(item.id);
      if (index === -1) {
        this.selectedItems.push(item.id);
        if (this.table.onSelectedItem) {
          this.table.onSelectedItem(item);
        }
      } else if (!selectAll) {
        this.selectedItems.splice(index, 1);
        if (this.table.onSelectedItem) {
          this.table.onSelectedItem(item);
        }
      }

    }
  }
  public clearSelection() {
    this.selectedPages = [];
    if (this.data) {
      this.data.forEach(item => {
        if (item.id) {
          if (this.itemCheckboxes[item.id].value) {
            this.onSelectedItem(item, false);
          }
          this.itemCheckboxes[item.id] = {
            ...this.itemCheckboxes[item.id],
            value: false
          };
          const $checkbox = document.getElementById(`checkbox-${item.id}`) as HTMLInputElement;
          if ($checkbox) {
            $checkbox.checked = false;
          }
        }
      });

      this.toggleCheckAll(false);

      if (this.selectedOnly) {
        if (this.searchString !== '') {
          this.searchData(this.searchString);
        } else {
          this.table.data = this.data;
        }
        this.selectedOnly = false;

      }

    }

  }
  public toggleAll() {
    this.allChecked = !this.allChecked;
    if (this.allChecked) {
      this.selectedPages.push(this.currentPage);
    } else {
      const index = this.selectedPages.indexOf(this.currentPage);
      this.selectedPages.splice(index, 1);
    }
    if (this.table.data) {

      this.table.data.filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = this.currentPage * this.pageSize;
        if (index >= start && index < end) {
          return true;
        }
      }).forEach(item => {
        if (item.id) {
          this.itemCheckboxes[item.id] = {
            ...this.itemCheckboxes[item.id],
            value: this.allChecked
          };
        }
        this.onSelectedItem(item, this.allChecked);
      });

      if (this.selectedOnly) {
        const selectedPages = Math.round(this.selectedItems.length / this.pageSize);
        for (let i = 0; i <= selectedPages; i++) {
          delete this.selectedPages[i];
        }
      }

    }

  }
  public toggleSelectedOnlyVisibility(visible: boolean) {
    this.currentPage = 1;
    this.selectedOnly = visible;

    this.toggleCheckAll(visible);

    if (this.data) {
      if (visible) {
        this.table.data = this.data.filter(item => item.id && this.selectedItems.indexOf(item.id) > -1);
      } else if (this.searchString !== '') {
        this.searchData(this.searchString);
      } else {
        this.table.data = this.data;
      }
    }
  }
  public rowClick(item: IAvailableTypes) {
    if (this.table.actions) {
      const rowAction = this.table.actions.find(action => action.position === 'row');
      if (rowAction && rowAction.route) {
        if (rowAction.route.name) {
          router.push(`${rowAction.route.name}/${rowAction.route.param ? item[rowAction.route.param] : ''}`);
        } else if (rowAction.route.url) {
          const tab = window.open(`${rowAction.route.url}/${rowAction.route.param ? item[rowAction.route.param] : ''}`, 'mywindow');

          if (tab) {
            tab.focus();
          }
        }
      } else if (this.table.onSelectedItem && item.id) {
        this.onSelectedItem(item);
        this.itemCheckboxes[item.id] = {
          ...this.itemCheckboxes[item.id],
          value: !this.itemCheckboxes[item.id].value
        };
      }

    }
  }
  public searchData(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchLoading = true;
    this.searchTimeout = setTimeout(() => {
      this.searchString = value;
      this.currentPage = 1;

      const fields = this.table.searchFields as { [key in IAvailableKeys]?: boolean };

      if (value === '') {
        const index = this.selectedPages.indexOf(this.currentPage);
        if (index === -1) {
          this.toggleCheckAll(false);
        } else {
          this.toggleCheckAll(true);
        }
      } else {
        this.toggleCheckAll(false);
      }

      if (this.data) {
        let data = this.data;

        if (this.selectedOnly) {
          data = data.filter(row => row.id && this.selectedItems.indexOf(row.id) !== -1);
        }

        this.table.data = search(value, data, fields);

        this.searchLoading = false;

      } else {

        this.table.data = this.data;
        this.searchLoading = false;

      }

    }, 600);

  }
  public openPreferences() {
    this.modal = {
      ...this.modal,
      isActive: true
    };
  } public openSearchSettings() {
    this.searchSettingsModal = {
      ...this.searchSettingsModal,
      isActive: true
    };
  }
  public refreshTable() {
    if (this.table.refreshAction) {
      this.table.data = undefined;
      store.dispatch(this.table.refreshAction);
    }
  }
  public sortData(field: IAvailableKeys) {
    let key: IAvailableKeys;

    if (!this.table.order) {
      return;
    }

    if (!this.table.order[field] || this.table.order[field] === '') {
      this.table.order[field] = 'desc';
    } else if (this.table.order[field] === 'asc') {
      this.table.order[field] = 'desc';
    } else if (this.table.order[field] === 'desc') {
      this.table.order[field] = 'asc';
    }

    for (key in this.table.order) {

      if (key !== field) {

        this.table.order[key] = '';

      }
    }

    if (this.table.data && this.table.order[field]) {
      const column = this.table.columns.find(column => column.key === field);
      if (column) {
        this.table.data = sort(column.childProp ? `${field}.${column.childProp}` : field, this.table.order[field] as string, this.table.data);
      }

    }
  }
}

export default Table;
