import { IAvailableKeys, IAvailableTypes } from '@/components/table';

export function search(query: string, data: Partial<IAvailableTypes>[], fields: { [key in IAvailableKeys]?: boolean }) {

  return data.filter((row: any) => {

    const searchFields = Object.keys(fields) as IAvailableKeys[];

    let wordsFound = true;
    let word = '';
    const words = query.split(' ');

    for (const key in words) {

      if (words[key] !== '' && wordsFound) {

        word = words[key].toLowerCase();

        wordsFound = searchFields.some(cell => {

          if (fields[cell] && `${row[cell]}`.toLowerCase().indexOf(word) > -1) {

            return true;

          }

        });

      }
    }

    return wordsFound;

  });

}

export default search;
